import { template as template_f541a2d2ac6b4ccd95ede8524fa1c3a7 } from "@ember/template-compiler";
const FKLabel = template_f541a2d2ac6b4ccd95ede8524fa1c3a7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
